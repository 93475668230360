<template>

  <div>
    <b-modal
      id="invoiceSendModal"
      ref="invoiceSendModal"
      title="Send Invoice Mail"
      hide-footer
      size="lg"
    >
      <invoice-send
        v-if="selectedInvoice"
        :invoice-data="selectedInvoice"
        @handleSend="handleInvoiceSend"
      />
    </b-modal>
    <upgrade-plan-modal ref="refUpgradePlanModal" />
    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >
      <b-overlay
        id="overlay-background"
        :show="pageIsLoading"
        variant="light"
        opacity="0.85"
        blur="5px"
        rounded="sm"
      >
        <template #overlay>
          <b-card

            class="text-center"
          >

            <h2>
              <b-spinner

                class="mr-1"
                label="Loading"
              />
              {{ overlayMsg }}
            </h2>

          </b-card>
        </template>
        <mark-as-paid-component
          ref="markAsPaidComponent"
          @paided="markAsPaidhandled"
        />
        <div
          v-if="firstDataCreated==false && pageIsLoading==false"
          class="m-2"
        >

          <b-row>
            <b-col md="12">
              <div class="d-flex flex-column align-items-center">
                <feather-icon
                  icon="FileTextIcon"
                  size="70"
                />
                <h1 class="mt-2">
                  Add invoice
                </h1>
                <span>Save your customers’ contact details for faster invoicing.</span>
                <b-button
                  class="mt-4"
                  variant="primary"
                  @click="handleCreateInvoiceButton"
                >
                  <span class="text-nowrap">{{ $t('Create Invoice') }}</span>
                </b-button>
              </div>
            </b-col>
          </b-row>

        </div>

        <div
          v-else
          class="m-2"
        >
          <vue-html2pdf
            ref="html2Pdf"
            :show-layout="false"
            :float-layout="true"
            :enable-download="false"
            :preview-modal="true"
            :paginate-elements-by-height="1100"
            filename="invoice"
            :pdf-quality="2"
            :manual-pagination="true"
            pdf-format="a4"
            pdf-orientation="portrait"

            pdf-content-width="800px"

            @startPagination="pdfHasStartedGeneration()"
            @hasPaginated="pdfHasGenerated($event)"
          >
            <section slot="pdf-content">
              <invoice-preview
                v-if="showPrint"
                :invoice-data="selectedInvoice"
              />
            </section>
          </vue-html2pdf>
          <!-- Table Top -->
          <b-row>

            <!-- Per Page -->
            <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
            />

            <!-- Search -->
            <b-col
              cols="12"
              md="12"
            >
              <div class="d-flex justify-content-between align-items-center column-is-mobile">
                <b-button-group
                  class="ml-1 mb-1"
                  size="lg"
                >
                  <b-button
                    id="filter-popover-btn"
                    size="lg"
                    variant="outline-secondary"
                    class="mr-2"
                  >
                    <feather-icon

                      icon="FilterIcon"
                      size="24"
                    />
                  </b-button>

                  <!-- Popover with Filter Options -->
                  <b-popover
                    target="filter-popover-btn"
                    placement="bottom"
                    triggers="click"
                    title="Optional Filters"
                  >
                    <!-- All Option -->
                    <b-form-group class="mb-2">
                      <b-form-radio
                        v-model="filterOption"
                        name="filter-options"
                        value="all"
                        @change="filterInvoices"
                      >
                        All
                      </b-form-radio>
                    </b-form-group>

                    <!-- Past Option -->
                    <b-form-group class="mb-2">
                      <b-form-radio
                        v-model="filterOption"
                        name="filter-options"
                        value="past"
                        @change="filterInvoices"
                      >
                        Past
                      </b-form-radio>
                    </b-form-group>

                    <!-- Incoming Option -->
                    <b-form-group class="mb-2">
                      <b-form-radio
                        v-model="filterOption"
                        name="filter-options"
                        value="incoming"
                        @change="filterInvoices"
                      >
                        Incoming
                      </b-form-radio>
                    </b-form-group>
                    <!-- Incoming Option -->
                    <b-form-group class="mb-2">
                      <b-form-radio
                        v-model="filterOption"
                        name="filter-options"
                        value="month"
                        @change="filterInvoices"
                      >
                        Current Month
                      </b-form-radio>
                    </b-form-group>
                    <!-- Incoming Option -->
                    <b-form-group class="mb-2">
                      <b-form-radio
                        v-model="filterOption"
                        name="filter-options"
                        value="year"
                        @change="filterInvoices"
                      >
                        Current Year
                      </b-form-radio>
                    </b-form-group>
                    <!-- Overdue Option -->
                    <b-form-group
                      v-if="filterType=='unpaid'"
                      class="mb-2"
                    >
                      <b-form-radio
                        v-model="filterOption"
                        name="filter-options"
                        value="overdue"
                        @change="filterInvoices"
                      >
                        Overdue
                      </b-form-radio>
                    </b-form-group>

                    <b-form-group
                      class="mb-2"
                    >
                      <b-form-radio
                        v-model="filterOption"
                        name="filter-options"
                        value="unsent"
                        @change="filterInvoices"
                      >
                        Unsent
                      </b-form-radio>
                    </b-form-group>

                    <b-form-group
                      class="mb-2"
                    >
                      <b-form-radio
                        v-model="filterOption"
                        name="filter-options"
                        value="recurring"
                        @change="filterInvoices"
                      >
                        Recurring
                      </b-form-radio>
                    </b-form-group>

                    <b-form-group
                      class="mb-2"
                    >
                      <b-form-radio
                        v-model="filterOption"
                        name="filter-options"
                        value="single"
                        @change="filterInvoices"
                      >
                        Single Payment
                      </b-form-radio>
                    </b-form-group>

                    <!-- Custom Option with Date Range Picker -->
                    <b-form-group class="mb-2">
                      <b-form-radio
                        v-model="filterOption"
                        name="filter-options"
                        value="custom"
                      >
                        Custom
                      </b-form-radio>

                      <!-- Date Range Input (only visible when 'Custom' is selected) -->
                      <div
                        v-if="filterOption === 'custom'"
                        class="mt-2"
                      >
                        <b-form-datepicker
                          v-model="startDate"
                          placeholder="Start Date"
                          size="sm"
                          locale="en"
                          class="mr-2 mt-1"
                          :date-format-options="{year: 'numeric', month: '2-digit', day: '2-digit'}"
                          @input="filterInvoices"
                        />

                        <b-form-datepicker
                          v-model="endDate"
                          placeholder="End Date"
                          size="sm"
                          locale="en"
                          :date-format-options="{year: 'numeric', month: '2-digit', day: '2-digit'}"
                          class="mt-1"
                          @input="filterInvoices"
                        />
                      </div>
                    </b-form-group>
                  </b-popover>

                  <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    :variant="filterType=='unpaid'?'primary':'outline-primary'"
                    @click="filterType='unpaid'"
                  >
                    Unpaid
                  </b-button>

                  <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    :variant="filterType=='paid'?'primary':'outline-primary'"
                    @click="filterType='paid'"
                  >
                    Paid
                  </b-button>

                </b-button-group>
                <b-button
                  size="lg"
                  variant="secondary"
                  @click="handleTestRecurring"
                >
                  <span class="text-nowrap">{{ $t('Test Recurring') }}</span>
                </b-button>

                <b-button
                  size="lg"
                  variant="primary"
                  @click="handleCreateInvoiceButton"
                >
                  <span class="text-nowrap">{{ $t('Create an invoice') }}</span>
                </b-button>
              </div>
            </b-col>
          </b-row>
          <hr>
        </div>
        <div>

          <b-table
            ref="refInvoiceListTable"
            style="min-height:300px;"
            class="position-relative"
            :items="fetchTickets"
            responsive
            :fields="tableColumns"
            primary-key="id"
            select-mode="single"
            :sort-by.sync="sortBy"
            striped
            hover

            show-empty
            :sort-desc.sync="isSortDirDesc"
            selectable
            empty-text="No matching records found"
            @row-selected="onRowSelected"
          >

            <template #cell(invoice_number)="data">
              <strong v-if="!data.item.content.recurring_number">#{{ data.item.content.invoice_number }}</strong>
              <strong v-else>#{{ data.item.content.invoice_number }} <small class="text-primary">{{ '-R' + data.item.content.recurring_count }}</small> </strong>

            </template>

            <template #cell(name)="data">
              <span class="font-weight-bold d-block text-nowrap">{{ data.item.name }}</span>
            </template>
            <template #cell(content.billing)="data">
              <span v-if="!data.item.content.billing"> [Invoice not selected]</span>
              <span v-else> {{ data.item.content.billing.name }}</span>
            </template>
            <template #cell(content.invoice_date)="data">
              <span> {{ $format_date(data.item.content.invoice_date) }}</span>

            </template>
            <template #cell(content.due_date)="data">
              <span> {{ $format_date(data.item.content.due_date) }}</span>

            </template>
            <template #cell(content.paid_date)="data">

              <span v-if="data.item.content.paid_description"> {{ $format_date(data.item.content.paid_date) }} | {{ data.item.content.paid_type_name }} | Note: {{ data.item.content.paid_description }}</span>
              <span v-else> {{ $format_date(data.item.content.paid_date) }} | {{ data.item.content.paid_type_name }} </span>

            </template>
            <template #cell(total)="data">
              <span> {{ data.item.content.currency_code }} {{ $staticParams.formatCurrency(calculateTotals(data.item).total) }}</span>

            </template>

            <template #cell(recurring_number)="data">
              <span>
                <b-badge
                  v-if="data.item.content.recurring_number"
                  variant="primary"
                >
                  Recurring
                </b-badge>
                <b-badge
                  v-else
                  variant="success"
                >
                  Single Payment
                </b-badge>
              </span>
            </template>

            <!-- Column: Actions -->
            <template #cell(actions)="data">
              <b-dropdown
                variant="link"
                no-caret
                :right="$store.state.appConfig.isRTL"
              >

                <template #button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="align-middle text-body"
                  />
                </template>
                <b-dropdown-item
                  v-if="filterType=='unpaid'"
                  variant="primary"
                  @click="markAsPaid(data.item)"
                >

                  <span class="align-middle font-weight-bolder">{{ $t('Mark as paid') }}</span>
                </b-dropdown-item>
                <hr>
                <b-dropdown-item

                  variant="primary"
                  @click="sendEmail(data.item.id)"
                >

                  <span class="align-middle font-weight-bolder">{{ $t('Email') }}</span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="true==false"

                  variant="primary"
                  @click="openInvoiceSendModal(data.item)"
                >

                  <span class="align-middle font-weight-bolder">{{ $t('Send Mail') }}</span>
                </b-dropdown-item>
                <b-dropdown-item

                  variant="primary"
                  @click="toPdf(data.item)"
                >

                  <span class="align-middle font-weight-bolder">{{ $t('Print') }}</span>
                </b-dropdown-item>

                <b-dropdown-item
                  variant="primary"
                  @click="duplicateInvoice(data.item)"
                >
                  <span class="align-middle font-weight-bolder">{{ $t('Duplicate') }}</span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="false"
                  variant="primary"
                  @click="duplicateRecurringInvoice(data.item)"
                >
                  <span class="align-middle font-weight-bolder">{{ $t('Duplicate Recurring') }}</span>
                </b-dropdown-item>
                <b-dropdown-item
                  variant="danger"
                  @click="deleteData(data.item.id)"
                >
                  <feather-icon icon="Trash2Icon" />
                  <span class="align-middle ml-50 font-weight-bolder">{{ $t('Delete') }}</span>
                </b-dropdown-item>
              </b-dropdown>
            </template>

          </b-table>
          <div class="mx-2 mb-2">
            <b-row>

              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-start"
              >
                <span class="text-muted">{{ $t('Showing') }} {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} {{ $t('Entries') }}</span>
              </b-col>
              <!-- Pagination -->
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-end"
              >

                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalInvoices"
                  :per-page="perPage"
                  first-number
                  last-number
                  class="mb-0 mt-1 mt-sm-0"
                  prev-class="prev-item"
                  next-class="next-item"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>

              </b-col>

            </b-row>
          </div>

        </div>
      </b-overlay>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BTable, BDropdown, BDropdownItem, BPagination, BButton, BOverlay, BSpinner, BButtonGroup, BModal, BPopover, BFormGroup, BFormRadio, BFormDatepicker, BBadge,
} from 'bootstrap-vue'
import store from '@/store'
import Ripple from 'vue-ripple-directive'
import {
  ref, onUnmounted, watch, computed,
} from '@vue/composition-api'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import invoiceCalculations from '@core/components/invoice-themes/calculations'
import invoicePreview from '@core/components/invoice-themes/Template.vue'
import VueHtml2pdf from 'vue-html2pdf'
import upgradePlanModal from '@core/components/information-components/UpgradePlanModal.vue'
// eslint-disable-next-line import/no-cycle
import useJwt from '@/auth/jwt/useJwt'
import invoiceStoreModule from './invoiceStoreModule'
import markAsPaidComponent from './MarkAsPaid.vue'
import invoiceSend from './InvoiceSend.vue'

export default {
  components: {
    BCard,
    BRow,
    invoiceSend,
    BModal,
    upgradePlanModal,
    BCol,
    BButtonGroup,
    VueHtml2pdf,
    BTable,
    BDropdown,
    invoicePreview,
    BDropdownItem,
    markAsPaidComponent,
    BPagination,
    BButton,
    BOverlay,
    BSpinner,
    BPopover,
    BFormGroup,
    BFormRadio,
    BFormDatepicker,
    BBadge,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      selectedInvoice: null,
      showPrint: false,
      overlayMsg: 'Loading...',
    }
  },
  watch: {
    filterType() {
      this.$refs.refInvoiceListTable.refresh()
    },
    '$route.query.filterOption': 'updateFilterOption',
  },
  created() {
    // Check for the filterOption query parameter and set the filterOption accordingly
    const { filterOption } = this.$route.query ?? 'all'
    if (filterOption) {
      this.filterOption = filterOption // Set the filterOption from the query parameter
    }
  },
  methods: {
    updateFilterOption(newFilterOption) {
      // Handle the change in filterOption
      if (newFilterOption) {
        this.filterOption = newFilterOption
      }
    },
    // eslint-disable-next-line no-unused-vars
    markAsPaidhandled(paidData) {
      this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Invoice marked as paid',
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          })
       this.$refs.refInvoiceListTable.refresh()
    },
    openInvoiceSendModal(invoiceData) {
      this.selectedInvoice = invoiceData
      this.$refs.invoiceSendModal.show()
    },
    duplicateInvoice(invoiceData) {
      this.$swal({
        title: this.$t('Are you sure?'),
        text: this.$t('The invoice will be duplicated. Do you want to continue?'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('Yes, continue'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
      this.overlayMsg = 'Duplicating the invoice...'
      this.pageIsLoading = true
      store
        .dispatch('app-invoice/duplicateInvoice', invoiceData)
      // eslint-disable-next-line no-unused-vars
        .then(response => {
          this.isSuccess = true
          this.errorData = null
          this.successMsg = 'Invoice duplicated'
          this.pageIsLoading = false
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Invoice duplicated',
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          })
          this.$router.replace({ name: 'invoices-edit', params: { id: response.data.data.id } })
        })
        .catch(error => {
          this.pageIsLoading = false
          if (error.response.status === 422) {
            this.errorData = error.response.data
          }
        })
      }
    })
    },
    duplicateRecurringInvoice(invoiceData) {
      this.$swal({
        title: this.$t('Are you sure?'),
        text: this.$t('The invoice will be duplicated with the recurring settings. Do you want to continue?'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('Yes, continue'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
      this.overlayMsg = 'Duplicating the invoice...'
      this.pageIsLoading = true
      store
        .dispatch('app-invoice/duplicateRecurringInvoice', invoiceData)
      // eslint-disable-next-line no-unused-vars
        .then(response => {
          this.isSuccess = true
          this.errorData = null
          this.successMsg = 'Invoice duplicated'
          this.pageIsLoading = false
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Invoice duplicated',
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          })
          this.$router.replace({ name: 'invoices-edit', params: { id: response.data.data.id } })
        })
        .catch(error => {
          this.pageIsLoading = false
          if (error.response.status === 422) {
            this.errorData = error.response.data
          }
        })
      }
    })
    },
    handleInvoiceSend(sendData) {
      // this.saveData()
      this.sendInvoiceMail(sendData)
    },
    sendInvoiceMail(mailContent) {
      this.overlayMsg = 'Invoice sending to mail...'
      this.pageIsLoading = true
      store
        .dispatch('app-invoice/sendMail', { invoice_id: this.selectedInvoice.id, content: mailContent })
      // eslint-disable-next-line no-unused-vars
        .then(response => {
          this.isSuccess = true
          this.errorData = null
          this.successMsg = 'Invoice sended'
          this.pageIsLoading = false
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Invoice sent by e-mail',
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          })
        })
        .catch(error => {
          this.pageIsLoading = false
          if (error.response.status === 422) {
            this.errorData = error.response.data
          }
        })
    },
    handleCreateInvoiceButton() {
      const { limits } = this.$user()

      let access = true
      if (limits.remaining_invoice_limit != null) {
        if (limits.remaining_invoice_limit <= 0) {
          access = false
          this.$refs.refUpgradePlanModal.show()
        }
      }

      if (access) {
        this.$destroy()
        this.$router.replace({ name: 'invoices-add' })
      }
    },
    handleTestRecurring() {
      store.dispatch('app-invoice/recurringTest')
            .then(() => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Invoice was deleted',
                  icon: 'CheckIcon',
                  variant: 'success',
                },
              })
              this.$refs.refInvoiceListTable.refresh()
            })
            .catch(() => {

            })
    },

    pdfHasStartedGeneration() {

    },
    toPdf(invoiceData) {
      this.overlayMsg = 'Printing...'
      const excelUrl = `${process.env.VUE_APP_API_URL}/api/invoices-download/${invoiceData.id}?token=${useJwt.getToken()}`
      window.location.href = `${excelUrl}`
      /* changed as backend downloadable
      this.pageIsLoading = true
      this.selectedInvoice = invoiceData
      this.showPrint = true

      setTimeout(() => this.$refs.html2Pdf.generatePdf(), 3000)
      */
    },
    pdfHasGenerated() {
      setTimeout(() => {
        this.showPrint = false
        this.selectedInvoice = false
        this.pageIsLoading = false
        this.overlayMsg = 'Loading...'
      }, 3000)
    },
    markAsPaid(invoiceData) {
      this.$refs.markAsPaidComponent.showEditor(invoiceData)
    },
    calculateTotals(invoiceData) {
      return invoiceCalculations.calculateTotals(invoiceData).content.totals
    },
    onRowSelected(items) {
      this.$destroy()
      this.$router.replace({ name: 'invoices-edit', params: { id: items[0].id } })
    },
    deleteData(id) {
      this.$swal({
        title: this.$t('Are you sure ?'),
        text: this.$t('You are about to delete a invoice. This action cannot be undone'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('Yes, delete now'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          store.dispatch('app-invoice/deleteInvoice', { id })
            .then(() => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Invoice was deleted',
                  icon: 'CheckIcon',
                  variant: 'success',
                },
              })
              this.$refs.refInvoiceListTable.refresh()
            })
            .catch(() => {

            })
        }
      })
    },
    filterInvoices() {
      if (this.filterOption === 'custom') {
        // Filter invoices based on the custom date range
        if (this.startDate != null && this.endDate != null) {
          this.startDate = new Date(this.startDate)
          this.endDate = new Date(this.endDate)
          this.$refs.refInvoiceListTable.refresh()
        }
      } else {
        this.startDate = null
        this.endDate = null
        this.$refs.refInvoiceListTable.refresh()
      }
    },
    sendEmail(invoiceId) {
      this.$destroy()
      this.$router.push({
        name: 'invoices-edit',
        params: {
          id: invoiceId,
          activeTab: 'send',
        },
      })
    },
  },
  setup() {
    const APP_STORE_MODULE_NAME = 'app-invoice'

    // Register module
    if (!store.hasModule(APP_STORE_MODULE_NAME)) store.registerModule(APP_STORE_MODULE_NAME, invoiceStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(APP_STORE_MODULE_NAME)) store.unregisterModule(APP_STORE_MODULE_NAME)
    })

    const toast = useToast()
    const filterType = ref('unpaid')
    const filterOption = ref('all')
    const startDate = ref(null)
    const endDate = ref(null)
    const perPageOptions = [10, 25, 50, 100]
    const perPage = ref(10)
    const searchQuery = ref('')
    const totalInvoices = ref(0)
    const firstDataCreated = ref(false)
    const refInvoiceListTable = ref(null)
    const currentPage = ref(1)
    const pageIsLoading = ref(true)
    const sortBy = ref('content.invoice_date')
    const isSortDirDesc = ref(true)
    const unpaidTableColumns = [
      {
        key: 'invoice_number', label: 'Number', sortable: true,
      },
      { key: 'content.billing', label: 'INVOICE', sortable: true },
      { key: 'content.invoice_date', label: 'Date', sortable: true },
      { key: 'content.due_date', label: 'Due date', sortable: true },
      { key: 'total', label: 'Total', sortable: false },
      {
        key: 'recurring_number', label: 'type', sortable: true,
      },

      {
        key: 'actions', thClass: 'text-right', label: 'ACTIONS', tdClass: 'text-right',
      },
    ]
    const paidTableColumns = [
      {
        key: 'invoice_number', label: 'Number', sortable: true,
      },
      { key: 'content.billing', label: 'INVOICE', sortable: true },
      { key: 'content.invoice_date', label: 'Date', sortable: true },
      {
      key: 'content.paid_date', label: 'PAID INFO', thStyle: 'width:30%', sortable: true,
      },
      { key: 'total', label: 'Total', sortable: false },
      {
        key: 'recurring_number', label: 'type', sortable: true,
      },

      {
      key: 'actions', label: 'ACTIONS', thClass: 'text-right', tdClass: 'text-right',
      },
    ]
    const tableColumns = ref(unpaidTableColumns)

    const refetchData = () => {
      refInvoiceListTable.value.refresh()
    }

    watch([currentPage, perPage, searchQuery], () => {
      refetchData()
    })
    const dataMeta = computed(() => {
      const localItemsCount = refInvoiceListTable.value ? refInvoiceListTable.value.localItems.length : 0
      return {
        from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
        to: perPage.value * (currentPage.value - 1) + localItemsCount,
        of: totalInvoices.value,
      }
    })
    const fetchTickets = (ctx, callback) => {
      pageIsLoading.value = true
      store
        .dispatch('app-invoice/fetchInvoices', {
          q: searchQuery.value,
          perPage: perPage.value,
          page: currentPage.value,
          sortBy: sortBy.value,
          sortDesc: isSortDirDesc.value,
          filterType: filterType.value,
          filterOption: filterOption.value,
          startDate: startDate.value,
          endDate: endDate.value,
        })
        .then(response => {
          if (filterType.value === 'paid') {
            tableColumns.value = paidTableColumns
          } else if (filterType.value === 'unpaid') {
            tableColumns.value = unpaidTableColumns
          }
          pageIsLoading.value = false
          const { data } = response.data
          const { total } = response.data.meta
          callback(data)
          totalInvoices.value = total
          firstDataCreated.value = response.data.meta.first_invoice_created
        })
        .catch(() => {
          pageIsLoading.value = false
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching tickets list',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }
    return {

      tableColumns,
      // Filter
      perPageOptions,
      perPage,
      dataMeta,
      fetchTickets,
      searchQuery,
      totalInvoices,
      firstDataCreated,
      currentPage,
      filterType,
      refetchData,
      // UI
      refInvoiceListTable,
      pageIsLoading,
      isSortDirDesc,
      sortBy,
      filterOption,
      startDate,
      endDate,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
@media (max-width: 800px) {
  .column-is-mobile {
    flex-direction: column;
  }
}
.table-width{
  max-width:150px;
}
.popover .b-form-datepicker {
  width: 100%;
  max-width: 150px;
  display: inline-block;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
